#cardFlexDirection{
 flex-direction: row;
}

@media(max-width: 768px){
    #cardFlexDirection{
        flex-direction: column;
       }
    
    .buttonFontSize{
        font-size: 12px;
    }
}