#bonusPaymentModal.modal-dialog {
    max-width: 800px;
}

#credit-bonus {
    font-size: 13px;
}

@media (max-width: "556px") {
    #credit-bonus {
        font-size: 12px;
    }
}
