#langugeDropdown {
    background-color: #f8f8f8 !important;
    color: black;
}

@media only screen and (max-width: 768px) {
    #langugeDropdown {
        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0 !important;
        margin-bottom: 5px;
    }
}

#input-form:focus {
    box-shadow: none;
    border: 1px solid lightgray;
}

#login-col {
    padding: 0;
    height: 85vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    padding-bottom: 120px;
}

#login-form {
    margin: auto auto;
}

/* SignUp page */
#signUp-col-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#signIn-col {
    text-align: center;
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 120px;
}

#signUp-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.theme-form .form-group {
    margin-bottom: 5px !important;
}

#mobile_scroll::-webkit-scrollbar {
    display: none;
}

// .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
//     padding: 0px 0px;
//   }
  
//   .page-wrapper .page-body-wrapper {
//     background-color: #f8f8f8;
//     background-image: url("https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/Login+background-03.jpg");
//     background-size: cover;
//   }
  
//   #manageMoney-btn {
//     background-color: #3179ff !important;
//     border-color: #3179ff !important;
//   }
  
//   .page-wrapper.compact-wrapper .page-main-header.close_icon {
//     margin: 0;
//     width: 100%;
//   }
  
//   .page-wrapper.compact-wrapper .page-main-header .main-header-right .logo-wrapper {
//     display: block;
//   }
  
//   .form-control {
//     border-radius: 0;
//     border: 1px solid #e0e0e0 !important;
//   }
  
//   .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav.close_icon ~ .page-body,
//   .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav.close_icon ~ footer {
//     margin-left: 0;
//   }
  
//   .container,
//   .container-fluid,
//   .container-sm,
//   .container-md,
//   .container-lg,
//   .container-xl,
//   .container-xxl {
//     margin-left: 0;
//     margin-right: 0;
//     max-width: 100%;
//     padding-left: 20px;
//     padding-right: 20px;
//   }
  
//   .card {
//     border-radius: 0;
//   }
  
//   .card-absolute .card-header,
//   .btn {
//     border-radius: 0;
//   }
  
//   .page-main-header .main-header-right {
//     justify-content: flex-start;
//   }
  
//   #nav-header .navbar {
//     padding: 0;
//   }
  
//   #nav-header .dropdown button {
//     padding-right: 1.5rem;
//   }
  
//   #nav-header .navbar-expand-lg .navbar-nav .nav-link {
//     padding: 0;
//   }
  
//   .nav-link {
//     padding: 0.5rem 1rem;
//   }
  
//   #publicPageHeader {
//     justify-content: space-between;
//   }
  
//   .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
//     margin-top: 125px;
//   }
  
//   .navbar-light .navbar-nav .nav-link.active {
//     color: #f9b700;
//   }
  
//   .card .card-header {
//     border-radius: 0;
//   }
  
//   .dropdown-divider {
//     margin: 0;
//   }
  
//   .dropdown-item {
//     padding: 0.5rem 1.5rem;
//   }
  
//   .dropdown-menu.show,
//   .profile-dropdown {
//     box-shadow: 0px 2px 7px 0px #ccc;
//   }
  
//   .dropdown-menu {
//     left: 25px !important;
//   }
  
//   #main-navigation {
//     display: none;
//   }
  
//   .form-control {
//     border: none;
//   }
  
//   textarea {
//     resize: none;
//   }
  
//   ::-webkit-input-placeholder {
//     margin: auto 0;
//   }
  
//   :-moz-placeholder {
//     /* Firefox 18- */
//     margin: auto 0;
//   }
  
//   ::-moz-placeholder {
//     /* Firefox 19+ */
//     margin: auto 0;
//   }
  
//   :-ms-input-placeholder {
//     margin: auto 0;
//   }
  
  .verify-KYC-image {
    display: flex;
    justify-content: space-evenly;
  }
  
  .KYC-example-image {
    max-width: 100%;
    height: 150px;
  }
  
  .KYC-image-user-page{
    max-width: 100%;
    height: 250px;
    width: 400px;
  }
  
  .verify-auth-button {
    border: none;
    color: white;
    padding: 0.375rem 1.75rem;
    background-color: #f9b600;
  }
  
  .verify-auth-button:disabled {
    border: 1px rgba(118, 118, 118, 0.3) solid;
    color: rgba(16, 16, 16, 0.3);
    padding: 0.375rem 1.75rem;
    background-color: rgba(239, 239, 239, 0.3);
    cursor: not-allowed;
  }
  
//   /* Large desktops and laptops */
//   @media (min-width: 1200px) {
//     .page-main-header .main-header-right {
//       padding: 15px 30px;
//     }
  
//     #rightBarHeader {
//       max-width: 80%;
//     }
  
//     .logo {
//       margin-left: 30px;
//     }
  
//     .padding-verify-right {
//       padding-right: 0px !important;
//     }
  
//     .padding-verify-left {
//       padding-left: 0px !important;
//     }
//   }
  
//   /* Landscape tablets and medium desktops */
//   @media (min-width: 992px) and (max-width: 1199px) {
//     #rightBarHeader {
//       max-width: 75%;
//     }
//   }
  
//   @media (max-width: 992px) {
//     .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
//       margin-top: 60px;
//     }
  
//     #rightBarHeader {
//       max-width: 60%;
//     }
//     .toggle-sidebar {
//       display: none !important;
//     }
  
//     #leftBar-navigator .dropdown-menu {
//       top: 100% !important;
//       transform: translate3d(0px, -10px, 0px) !important;
//       height: 300px;
//       overflow-y: scroll;
//     }
  
//     #main-navigation {
//       display: block;
//     }
  
//     #img-header-logo {
//       max-width: 75%;
//     }
  
//     .logo-wrapper {
//       margin-left: auto;
//       margin-right: -80px;
//     }
  
//     .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .logo-wrapper .back-btn {
//       float: left;
//     }
  
//     .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .logo-wrapper {
//       box-shadow: none;
//     }
  
//     .fa-angle-left:before {
//       margin-left: 6px;
//       font-size: 25px;
//     }
  
//     .modal-dialog {
//       max-width: 90%;
//       margin: 3.75rem auto !important;
//     }
  
//     .padding-verify-right {
//       padding-right: 15px !important;
//     }
  
//     .padding-verify-left {
//       padding-left: 15px !important;
//     }
//   }
  
  /* Portrait tablets and small desktops */
  // @media (min-width: 768px) and (max-width: 991px) {
  
  // }
  
  /* Landscape phones and portrait tablets */
  // @media (max-width: 991px) {
  
  // }
  
  /* Portrait phones and smaller */
  @media (max-width: 480px) {
    // .main-header-left {
    //   margin: 0 auto;
    //   margin-right: 0;
    // }
    // .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
    //   margin-top: 60px;
    // }
  
    // .mobile-dropdown-header {
    //   color: #f6b600;
    // }
  
    // .dropdown-menu {
    //   left: 0px !important;
    //   top: -30px !important;
    //   overflow-y: scroll;
    // }
  
    .verify-KYC-Mobileimage {
      flex-direction: column;
    }
  
    .text-center-sm {
      text-align: center;
    }
  
    // .nav-link {
    //   padding: 0px;
    // }
  
    // #publicPageHeader {
    //   justify-content: center;
    // }
  }
  



@media only screen and (max-width: 1000px) {
    #signIn-col {
        order: 1;
    }
}
@media only screen and (max-width: 768px) {
    #signIn-col {
        height: 35%;
        order: 1;
        padding: 60px;
    }

    #login-col {
        height: 77vh;
    }

    #login-form {
        max-width: 80%;
        width: 90%;
    }

    #registerForm {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {
    #login-col {
        height: 70vh;
    }
}

@media only screen and (max-width: 320px) {
    #registerForm {
        font-size: 12px;
    }
}
