/* Foundation */

/* Font path variable */
$icon-font-path: '../../fonts/open-iconic/' !default;

@font-face {
  font-family: 'Icons';
  src: url('#{$icon-font-path}open-iconic.eot');
  src: url('#{$icon-font-path}open-iconic.eot?#iconic-sm') format('embedded-opentype'), url('#{$icon-font-path}open-iconic.woff') format('woff'), url('#{$icon-font-path}open-iconic.ttf') format('truetype'), url('#{$icon-font-path}open-iconic.otf') format('opentype'), url('#{$icon-font-path}open-iconic.svg#iconic-sm') format('svg');
  font-weight: normal;
  font-style: normal;
}


.fi-account-login:before,

.fi-account-logout:before,

.fi-action-redo:before,

.fi-action-undo:before,

.fi-align-center:before,

.fi-align-left:before,

.fi-align-right:before,

.fi-aperture:before,

.fi-arrow-bottom:before,

.fi-arrow-circle-bottom:before,

.fi-arrow-circle-left:before,

.fi-arrow-circle-right:before,

.fi-arrow-circle-top:before,

.fi-arrow-left:before,

.fi-arrow-right:before,

.fi-arrow-thick-bottom:before,

.fi-arrow-thick-left:before,

.fi-arrow-thick-right:before,

.fi-arrow-thick-top:before,

.fi-arrow-top:before,

.fi-audio-spectrum:before,

.fi-audio:before,

.fi-badge:before,

.fi-ban:before,

.fi-bar-chart:before,

.fi-basket:before,

.fi-battery-empty:before,

.fi-battery-full:before,

.fi-beaker:before,

.fi-bell:before,

.fi-bluetooth:before,

.fi-bold:before,

.fi-bolt:before,

.fi-book:before,

.fi-bookmark:before,

.fi-box:before,

.fi-briefcase:before,

.fi-british-pound:before,

.fi-browser:before,

.fi-brush:before,

.fi-bug:before,

.fi-bullhorn:before,

.fi-calculator:before,

.fi-calendar:before,

.fi-camera-slr:before,

.fi-caret-bottom:before,

.fi-caret-left:before,

.fi-caret-right:before,

.fi-caret-top:before,

.fi-cart:before,

.fi-chat:before,

.fi-check:before,

.fi-chevron-bottom:before,

.fi-chevron-left:before,

.fi-chevron-right:before,

.fi-chevron-top:before,

.fi-circle-check:before,

.fi-circle-x:before,

.fi-clipboard:before,

.fi-clock:before,

.fi-cloud-download:before,

.fi-cloud-upload:before,

.fi-cloud:before,

.fi-cloudy:before,

.fi-code:before,

.fi-cog:before,

.fi-collapse-down:before,

.fi-collapse-left:before,

.fi-collapse-right:before,

.fi-collapse-up:before,

.fi-command:before,

.fi-comment-square:before,

.fi-compass:before,

.fi-contrast:before,

.fi-copywriting:before,

.fi-credit-card:before,

.fi-crop:before,

.fi-dashboard:before,

.fi-data-transfer-download:before,

.fi-data-transfer-upload:before,

.fi-delete:before,

.fi-dial:before,

.fi-document:before,

.fi-dollar:before,

.fi-double-quote-sans-left:before,

.fi-double-quote-sans-right:before,

.fi-double-quote-serif-left:before,

.fi-double-quote-serif-right:before,

.fi-droplet:before,

.fi-eject:before,

.fi-elevator:before,

.fi-ellipses:before,

.fi-envelope-closed:before,

.fi-envelope-open:before,

.fi-euro:before,

.fi-excerpt:before,

.fi-expand-down:before,

.fi-expand-left:before,

.fi-expand-right:before,

.fi-expand-up:before,

.fi-external-link:before,

.fi-eye:before,

.fi-eyedropper:before,

.fi-file:before,

.fi-fire:before,

.fi-flag:before,

.fi-flash:before,

.fi-folder:before,

.fi-fork:before,

.fi-fullscreen-enter:before,

.fi-fullscreen-exit:before,

.fi-globe:before,

.fi-graph:before,

.fi-grid-four-up:before,

.fi-grid-three-up:before,

.fi-grid-two-up:before,

.fi-hard-drive:before,

.fi-header:before,

.fi-headphones:before,

.fi-heart:before,

.fi-home:before,

.fi-image:before,

.fi-inbox:before,

.fi-infinity:before,

.fi-info:before,

.fi-italic:before,

.fi-justify-center:before,

.fi-justify-left:before,

.fi-justify-right:before,

.fi-key:before,

.fi-laptop:before,

.fi-layers:before,

.fi-lightbulb:before,

.fi-link-broken:before,

.fi-link-intact:before,

.fi-list-rich:before,

.fi-list:before,

.fi-location:before,

.fi-lock-locked:before,

.fi-lock-unlocked:before,

.fi-loop-circular:before,

.fi-loop-square:before,

.fi-loop:before,

.fi-magnifying-glass:before,

.fi-map-marker:before,

.fi-map:before,

.fi-media-pause:before,

.fi-media-play:before,

.fi-media-record:before,

.fi-media-skip-backward:before,

.fi-media-skip-forward:before,

.fi-media-step-backward:before,

.fi-media-step-forward:before,

.fi-media-stop:before,

.fi-medical-cross:before,

.fi-menu:before,

.fi-microphone:before,

.fi-minus:before,

.fi-monitor:before,

.fi-moon:before,

.fi-move:before,

.fi-musical-note:before,

.fi-paperclip:before,

.fi-pencil:before,

.fi-people:before,

.fi-person:before,

.fi-phone:before,

.fi-pie-chart:before,

.fi-pin:before,

.fi-play-circle:before,

.fi-plus:before,

.fi-power-standby:before,

.fi-print:before,

.fi-project:before,

.fi-pulse:before,

.fi-puzzle-piece:before,

.fi-question-mark:before,

.fi-rain:before,

.fi-random:before,

.fi-reload:before,

.fi-resize-both:before,

.fi-resize-height:before,

.fi-resize-width:before,

.fi-rss-alt:before,

.fi-rss:before,

.fi-script:before,

.fi-share-boxed:before,

.fi-share:before,

.fi-shield:before,

.fi-signal:before,

.fi-signpost:before,

.fi-sort-ascending:before,

.fi-sort-descending:before,

.fi-spreadsheet:before,

.fi-star:before,

.fi-sun:before,

.fi-tablet:before,

.fi-tag:before,

.fi-tags:before,

.fi-target:before,

.fi-task:before,

.fi-terminal:before,

.fi-text:before,

.fi-thumb-down:before,

.fi-thumb-up:before,

.fi-timer:before,

.fi-transfer:before,

.fi-trash:before,

.fi-underline:before,

.fi-vertical-align-bottom:before,

.fi-vertical-align-center:before,

.fi-vertical-align-top:before,

.fi-video:before,

.fi-volume-high:before,

.fi-volume-low:before,

.fi-volume-off:before,

.fi-warning:before,

.fi-wifi:before,

.fi-wrench:before,

.fi-x:before,

.fi-yen:before,

.fi-zoom-in:before,

.fi-zoom-out:before
 {
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-decoration: inherit;
}


[class*='fi-'].oi-align-center:before {
  text-align: center;
}

[class*='fi-'].oi-align-left:before {
  text-align: left;
}

[class*='fi-'].oi-align-right:before {
  text-align: right;
}


[class*='fi-'].oi-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

[class*='fi-'].oi-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(-1, 1);
  transform: scale(1, -1);
}

[class*='fi-'].oi-flip-horizontal-vertical:before {
  -webkit-transform: scale(-1, -1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, -1);
}



.fi-account-login:before {
  content:'\e000';
}

.fi-account-logout:before {
  content:'\e001';
}

.fi-action-redo:before {
  content:'\e002';
}

.fi-action-undo:before {
  content:'\e003';
}

.fi-align-center:before {
  content:'\e004';
}

.fi-align-left:before {
  content:'\e005';
}

.fi-align-right:before {
  content:'\e006';
}

.fi-aperture:before {
  content:'\e007';
}

.fi-arrow-bottom:before {
  content:'\e008';
}

.fi-arrow-circle-bottom:before {
  content:'\e009';
}

.fi-arrow-circle-left:before {
  content:'\e00a';
}

.fi-arrow-circle-right:before {
  content:'\e00b';
}

.fi-arrow-circle-top:before {
  content:'\e00c';
}

.fi-arrow-left:before {
  content:'\e00d';
}

.fi-arrow-right:before {
  content:'\e00e';
}

.fi-arrow-thick-bottom:before {
  content:'\e00f';
}

.fi-arrow-thick-left:before {
  content:'\e010';
}

.fi-arrow-thick-right:before {
  content:'\e011';
}

.fi-arrow-thick-top:before {
  content:'\e012';
}

.fi-arrow-top:before {
  content:'\e013';
}

.fi-audio-spectrum:before {
  content:'\e014';
}

.fi-audio:before {
  content:'\e015';
}

.fi-badge:before {
  content:'\e016';
}

.fi-ban:before {
  content:'\e017';
}

.fi-bar-chart:before {
  content:'\e018';
}

.fi-basket:before {
  content:'\e019';
}

.fi-battery-empty:before {
  content:'\e01a';
}

.fi-battery-full:before {
  content:'\e01b';
}

.fi-beaker:before {
  content:'\e01c';
}

.fi-bell:before {
  content:'\e01d';
}

.fi-bluetooth:before {
  content:'\e01e';
}

.fi-bold:before {
  content:'\e01f';
}

.fi-bolt:before {
  content:'\e020';
}

.fi-book:before {
  content:'\e021';
}

.fi-bookmark:before {
  content:'\e022';
}

.fi-box:before {
  content:'\e023';
}

.fi-briefcase:before {
  content:'\e024';
}

.fi-british-pound:before {
  content:'\e025';
}

.fi-browser:before {
  content:'\e026';
}

.fi-brush:before {
  content:'\e027';
}

.fi-bug:before {
  content:'\e028';
}

.fi-bullhorn:before {
  content:'\e029';
}

.fi-calculator:before {
  content:'\e02a';
}

.fi-calendar:before {
  content:'\e02b';
}

.fi-camera-slr:before {
  content:'\e02c';
}

.fi-caret-bottom:before {
  content:'\e02d';
}

.fi-caret-left:before {
  content:'\e02e';
}

.fi-caret-right:before {
  content:'\e02f';
}

.fi-caret-top:before {
  content:'\e030';
}

.fi-cart:before {
  content:'\e031';
}

.fi-chat:before {
  content:'\e032';
}

.fi-check:before {
  content:'\e033';
}

.fi-chevron-bottom:before {
  content:'\e034';
}

.fi-chevron-left:before {
  content:'\e035';
}

.fi-chevron-right:before {
  content:'\e036';
}

.fi-chevron-top:before {
  content:'\e037';
}

.fi-circle-check:before {
  content:'\e038';
}

.fi-circle-x:before {
  content:'\e039';
}

.fi-clipboard:before {
  content:'\e03a';
}

.fi-clock:before {
  content:'\e03b';
}

.fi-cloud-download:before {
  content:'\e03c';
}

.fi-cloud-upload:before {
  content:'\e03d';
}

.fi-cloud:before {
  content:'\e03e';
}

.fi-cloudy:before {
  content:'\e03f';
}

.fi-code:before {
  content:'\e040';
}

.fi-cog:before {
  content:'\e041';
}

.fi-collapse-down:before {
  content:'\e042';
}

.fi-collapse-left:before {
  content:'\e043';
}

.fi-collapse-right:before {
  content:'\e044';
}

.fi-collapse-up:before {
  content:'\e045';
}

.fi-command:before {
  content:'\e046';
}

.fi-comment-square:before {
  content:'\e047';
}

.fi-compass:before {
  content:'\e048';
}

.fi-contrast:before {
  content:'\e049';
}

.fi-copywriting:before {
  content:'\e04a';
}

.fi-credit-card:before {
  content:'\e04b';
}

.fi-crop:before {
  content:'\e04c';
}

.fi-dashboard:before {
  content:'\e04d';
}

.fi-data-transfer-download:before {
  content:'\e04e';
}

.fi-data-transfer-upload:before {
  content:'\e04f';
}

.fi-delete:before {
  content:'\e050';
}

.fi-dial:before {
  content:'\e051';
}

.fi-document:before {
  content:'\e052';
}

.fi-dollar:before {
  content:'\e053';
}

.fi-double-quote-sans-left:before {
  content:'\e054';
}

.fi-double-quote-sans-right:before {
  content:'\e055';
}

.fi-double-quote-serif-left:before {
  content:'\e056';
}

.fi-double-quote-serif-right:before {
  content:'\e057';
}

.fi-droplet:before {
  content:'\e058';
}

.fi-eject:before {
  content:'\e059';
}

.fi-elevator:before {
  content:'\e05a';
}

.fi-ellipses:before {
  content:'\e05b';
}

.fi-envelope-closed:before {
  content:'\e05c';
}

.fi-envelope-open:before {
  content:'\e05d';
}

.fi-euro:before {
  content:'\e05e';
}

.fi-excerpt:before {
  content:'\e05f';
}

.fi-expand-down:before {
  content:'\e060';
}

.fi-expand-left:before {
  content:'\e061';
}

.fi-expand-right:before {
  content:'\e062';
}

.fi-expand-up:before {
  content:'\e063';
}

.fi-external-link:before {
  content:'\e064';
}

.fi-eye:before {
  content:'\e065';
}

.fi-eyedropper:before {
  content:'\e066';
}

.fi-file:before {
  content:'\e067';
}

.fi-fire:before {
  content:'\e068';
}

.fi-flag:before {
  content:'\e069';
}

.fi-flash:before {
  content:'\e06a';
}

.fi-folder:before {
  content:'\e06b';
}

.fi-fork:before {
  content:'\e06c';
}

.fi-fullscreen-enter:before {
  content:'\e06d';
}

.fi-fullscreen-exit:before {
  content:'\e06e';
}

.fi-globe:before {
  content:'\e06f';
}

.fi-graph:before {
  content:'\e070';
}

.fi-grid-four-up:before {
  content:'\e071';
}

.fi-grid-three-up:before {
  content:'\e072';
}

.fi-grid-two-up:before {
  content:'\e073';
}

.fi-hard-drive:before {
  content:'\e074';
}

.fi-header:before {
  content:'\e075';
}

.fi-headphones:before {
  content:'\e076';
}

.fi-heart:before {
  content:'\e077';
}

.fi-home:before {
  content:'\e078';
}

.fi-image:before {
  content:'\e079';
}

.fi-inbox:before {
  content:'\e07a';
}

.fi-infinity:before {
  content:'\e07b';
}

.fi-info:before {
  content:'\e07c';
}

.fi-italic:before {
  content:'\e07d';
}

.fi-justify-center:before {
  content:'\e07e';
}

.fi-justify-left:before {
  content:'\e07f';
}

.fi-justify-right:before {
  content:'\e080';
}

.fi-key:before {
  content:'\e081';
}

.fi-laptop:before {
  content:'\e082';
}

.fi-layers:before {
  content:'\e083';
}

.fi-lightbulb:before {
  content:'\e084';
}

.fi-link-broken:before {
  content:'\e085';
}

.fi-link-intact:before {
  content:'\e086';
}

.fi-list-rich:before {
  content:'\e087';
}

.fi-list:before {
  content:'\e088';
}

.fi-location:before {
  content:'\e089';
}

.fi-lock-locked:before {
  content:'\e08a';
}

.fi-lock-unlocked:before {
  content:'\e08b';
}

.fi-loop-circular:before {
  content:'\e08c';
}

.fi-loop-square:before {
  content:'\e08d';
}

.fi-loop:before {
  content:'\e08e';
}

.fi-magnifying-glass:before {
  content:'\e08f';
}

.fi-map-marker:before {
  content:'\e090';
}

.fi-map:before {
  content:'\e091';
}

.fi-media-pause:before {
  content:'\e092';
}

.fi-media-play:before {
  content:'\e093';
}

.fi-media-record:before {
  content:'\e094';
}

.fi-media-skip-backward:before {
  content:'\e095';
}

.fi-media-skip-forward:before {
  content:'\e096';
}

.fi-media-step-backward:before {
  content:'\e097';
}

.fi-media-step-forward:before {
  content:'\e098';
}

.fi-media-stop:before {
  content:'\e099';
}

.fi-medical-cross:before {
  content:'\e09a';
}

.fi-menu:before {
  content:'\e09b';
}

.fi-microphone:before {
  content:'\e09c';
}

.fi-minus:before {
  content:'\e09d';
}

.fi-monitor:before {
  content:'\e09e';
}

.fi-moon:before {
  content:'\e09f';
}

.fi-move:before {
  content:'\e0a0';
}

.fi-musical-note:before {
  content:'\e0a1';
}

.fi-paperclip:before {
  content:'\e0a2';
}

.fi-pencil:before {
  content:'\e0a3';
}

.fi-people:before {
  content:'\e0a4';
}

.fi-person:before {
  content:'\e0a5';
}

.fi-phone:before {
  content:'\e0a6';
}

.fi-pie-chart:before {
  content:'\e0a7';
}

.fi-pin:before {
  content:'\e0a8';
}

.fi-play-circle:before {
  content:'\e0a9';
}

.fi-plus:before {
  content:'\e0aa';
}

.fi-power-standby:before {
  content:'\e0ab';
}

.fi-print:before {
  content:'\e0ac';
}

.fi-project:before {
  content:'\e0ad';
}

.fi-pulse:before {
  content:'\e0ae';
}

.fi-puzzle-piece:before {
  content:'\e0af';
}

.fi-question-mark:before {
  content:'\e0b0';
}

.fi-rain:before {
  content:'\e0b1';
}

.fi-random:before {
  content:'\e0b2';
}

.fi-reload:before {
  content:'\e0b3';
}

.fi-resize-both:before {
  content:'\e0b4';
}

.fi-resize-height:before {
  content:'\e0b5';
}

.fi-resize-width:before {
  content:'\e0b6';
}

.fi-rss-alt:before {
  content:'\e0b7';
}

.fi-rss:before {
  content:'\e0b8';
}

.fi-script:before {
  content:'\e0b9';
}

.fi-share-boxed:before {
  content:'\e0ba';
}

.fi-share:before {
  content:'\e0bb';
}

.fi-shield:before {
  content:'\e0bc';
}

.fi-signal:before {
  content:'\e0bd';
}

.fi-signpost:before {
  content:'\e0be';
}

.fi-sort-ascending:before {
  content:'\e0bf';
}

.fi-sort-descending:before {
  content:'\e0c0';
}

.fi-spreadsheet:before {
  content:'\e0c1';
}

.fi-star:before {
  content:'\e0c2';
}

.fi-sun:before {
  content:'\e0c3';
}

.fi-tablet:before {
  content:'\e0c4';
}

.fi-tag:before {
  content:'\e0c5';
}

.fi-tags:before {
  content:'\e0c6';
}

.fi-target:before {
  content:'\e0c7';
}

.fi-task:before {
  content:'\e0c8';
}

.fi-terminal:before {
  content:'\e0c9';
}

.fi-text:before {
  content:'\e0ca';
}

.fi-thumb-down:before {
  content:'\e0cb';
}

.fi-thumb-up:before {
  content:'\e0cc';
}

.fi-timer:before {
  content:'\e0cd';
}

.fi-transfer:before {
  content:'\e0ce';
}

.fi-trash:before {
  content:'\e0cf';
}

.fi-underline:before {
  content:'\e0d0';
}

.fi-vertical-align-bottom:before {
  content:'\e0d1';
}

.fi-vertical-align-center:before {
  content:'\e0d2';
}

.fi-vertical-align-top:before {
  content:'\e0d3';
}

.fi-video:before {
  content:'\e0d4';
}

.fi-volume-high:before {
  content:'\e0d5';
}

.fi-volume-low:before {
  content:'\e0d6';
}

.fi-volume-off:before {
  content:'\e0d7';
}

.fi-warning:before {
  content:'\e0d8';
}

.fi-wifi:before {
  content:'\e0d9';
}

.fi-wrench:before {
  content:'\e0da';
}

.fi-x:before {
  content:'\e0db';
}

.fi-yen:before {
  content:'\e0dc';
}

.fi-zoom-in:before {
  content:'\e0dd';
}

.fi-zoom-out:before {
  content:'\e0de';
}

