.isActive {
    background-color: #f6b501;
    color: white !important;
}
.isFeedbackActive {
    color: #f6b501;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .mobile-font-size {
        font-size: 10px;
    }
}
