@media (max-width: 768px) {
  #liveWalletBalance {
    font-size: 14px !important;
  }

  #liveTotalDeposit {
    margin-top: 27px !important;
    font-size: 14px !important;
  }

  #liveDepositButton {
    width: 150px !important;
  }
}

@media (max-width: 500px) {
  #liveTotalDeposit {
    margin-top: 30px !important;
    font-size: 12px !important;
  }

  #liveWalletBalance {
    font-size: 12px !important;
  }
}

@media (max-width: 400px) {
  #liveDepositButton {
    width: 135px !important;
  }

  #liveDepositButtonText {
    font-size: 12px !important;
  }
}

@media (max-width: 327px) {
  #liveWalletBalance {
    font-size: 10px !important;
  }

  #liveTotalDeposit {
    font-size: 10px !important;
    margin-top: 33px !important;
  }

  #liveDepositButtonText {
    font-size: 10px !important;
  }
}
