.recentTransaction:hover {
  background-color: #c4c4c54d !important;
}

#recent-transaction-modal.modal-dialog {
  margin: 10.75rem auto !important;
}

#upgrade-live-modal.modal-dialog {
  margin: 1.75rem auto !important;
  min-width: 95% !important;
}

#package-live-modal.modal-dialog {
  min-width: 80% !important;
}

#transactionsDiv {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow: scroll;
}

#transactionsDiv::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.darkCardTransaction {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}
