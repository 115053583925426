@media (max-width: "778px") {
    #stepInviteFriend {
        flex-direction: column;
    }

    #stepInviteFriend h6 {
        text-align: center;
    }

    #stepInviteFriend div {
        margin-top: 10px;
    }
}
