#imageSize{
    width: 80px;
    height: 80px;
}

@media(max-width:370px){
    #imageSize{
        width: 50px;
        height: 50px;
    }

    #smallerFontSize{
        font-size: 8px;
    }
}