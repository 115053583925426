/**=====================
    63. Box-layout CSS start
==========================**/
[main-theme-layout="box-layout"] {
  @media only screen and (min-width: 1280px) {
    background-image: url(../../images/other-images/boxbg.jpg);
    background-blend-mode: overlay;
    background-color: rgba($white, 0.5);
    .calendar-sec {
      order: unset;
    }
    .chart_data_right.second {
      display: none !important;
    }
    .default-datepicker {
      .react-datepicker {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          line-height: 50px !important;
          width: 50px !important;
        }
      }
    }
    .offer-slider {
      .slick-slider {
        .carousel-item {
          padding: 30px 10px;
        }
      }
    }
    .time_picker_wrapper {
      width: 50%;
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          margin-bottom: 52px;
        }
      }
      &.horizontal_sidebar {
        .page-body-wrapper {
          .page-sidebar {
            width: 1280px;
            .sidebar-menu {
              .right-arrow {
                left: calc(270px + (1248 - 270) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
      }
      &.compact-wrapper {
        .page-body-wrapper {
          &.sidebar-icon {
            header {
              &.main-nav {
                .toggle-sidebar {
                  display: none;
                }
                &.close_icon {
                  width: 0px;
                  transform: translate(0px);
                  z-index: 0;
                  display: none;
                  visibility: hidden;
                  transition: 0.5s;
                  ~ footer {
                    padding-left: 15px;
                    margin-left: auto;
                  }
                  ~ .footer-fix {
                    margin-left: auto;
                    width: 1280px;
                  }
                  ~ .page-body {
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }
        .page-main-header {
          width: auto;
          .left-menu-header {
            margin-left: 40px;
          }
          .main-header-right {
            .toggle-sidebar {
              display: inline-block;
              left: 30px;
            }
          }
          &.close_icon {
            margin-left: auto;
            padding-left: 0;
            transition: $sidebar-transition;
          }
        }
      }
    }
    .page-wrapper.horizontal-wrapper {
      .page-body-wrapper {
        footer {
          width: 100%;
          margin-left: 0;
        }
        &.horizontal-menu {
          header {
            &.main-nav {
              width: 1280px;
              nav {
                overflow: hidden;
              }
              .main-navbar {
                #mainnav {
                  width: 1280px;
                  left: 0;
                  // right: 0;
                  // margin: 0 auto;
                  // position: relative;
                }
              }
            }
          }
        }
      }
      .page-main-header {
        padding-left: 0;
      }
    }
    .page-wrapper {
      ul {
        &.close_icon {
          > li {
            label {
              padding: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }

      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .page-main-header {
        .main-header-right {
          margin: 0;
        }
      }
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .active-order-table {
          max-width: 443px;
          overflow: auto;
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity {
          .media {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        margin: 0 auto;

        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 295px;
          position: fixed;
          left: 0;
          right: 0;
        }
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
        .footer-fix {
          padding-left: 280px;
          left: unset;
        }
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
              > div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }
              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
              img {
                width: 38%;
              }
            }
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget {
          img {
            height: 65px;
          }
        }
        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      .page-main-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left: 280px;
        &.close_icon {
          padding-left: 0;
          transition: $sidebar-transition;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .box-col-4 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
      }
      .chat-menu.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        transition: all linear 0.3s;
        padding-bottom: 25px;
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .vertical-menu-main {
        width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -320px !important;
        }
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
    // chat app
    .chat-box {
      .chat-menu {
        .people-list {
          ul {
            &.list {
              height: 350px;
              overflow: auto;
            }
          }
        }
      }
    }
    // cart
    .cart {
      .qty-box {
        width: 36%;
      }
    }
  }
}
.rtl {
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-main-header {
      max-width: 1280px;
      padding-right: 250px;
      padding-left: unset;
      &.open {
        max-width: 1280px;
      }
    }
    .page-body-wrapper {
      footer {
        margin-right: 566px;
        margin-left: unset;
        padding-left: 15px;
        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }
}
/**=====================
    63. Box-layout CSS ends
==========================**/
