.ClaimButtonAlert .swal2-header h2 {
    font-size: 1.875rem !important;
    color: #be132d;
}

#CnyModal .modal-content {
    position: absolute;
    bottom: 90px;
    width: 85%;
    left: 52px;
    border: none;
}

#CnyModal.modal-dialog {
    max-width: 616px !important;
}

@media (min-width: 1400px) {
    #CnyModal.modal-dialog {
        max-width: 681px !important;
    }
}

#CnyModal.modal-dialog-centered {
    min-height: 1150px;
}

@media (max-width: 700px) {

    #CnyModal {
        background-image: none !important;
    }

    #CnyModal.modal-dialog-centered {
        min-height: 700px;
    }

    #CnyModal .modal-content {
        position: absolute;
        width: 100%;
        left: 0px;
        border: none;
    }
}

.ClaimButtonAlert .swal2-actions {
    margin-top: 0;
}

.ClaimButtonAlert .swal2-actions button {
    background-color: #be132d !important;
}

.MuiSlider-root {
    color: #f9b91d !important;
}

.MuiSlider-rail {
    color: #a49494 !important;
}

.MuiSlider-thumb:not(.MuiSlider-active) {
    transition: left 0.1s ease-in;
}

.MuiSlider-track {
    transition: width 0.1s ease-in;
}

#tutorialModal .modal-content,
#tutorialModal {
    width: 90%;
    max-width: 90%;
}

#tutorialModal .modal-content {
    margin: 0 auto;
}

@media (max-width: 768px) {
    #totalDepositRow {
        flex-direction: column;
        align-items: center;
    }

    #totalDepositPartnerCard {
        margin-top: 10px;
    }
}

.pamm-follow-col {
    height: 378px;
}

@media (max-width: 1206px) {
    .pamm-follow-col {
        height: fit-content;
    }
}
@media (max-width: 556px) {
    #tutorialModal .modal-content,
    #tutorialModal {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
}

#achievementModal .modal-content,
#achievementModal {
    width: 600px;
    max-width: 600px;
    margin: 15rem auto;
}

.swal2-label {
    font-size: 16px;
}

.product-wrapper-grid header {
    height: 0px !important;
    line-height: 0px !important;
}

.carousel {
    width: 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f9b700' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f9b700' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

@media (max-width: 768px) {
    #mobileFontSize {
        font-size: 10px;
    }
}
