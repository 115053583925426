#displayGraph{
    padding: 0 25px;
}

@media (max-width: "768px") {
    .flex-direction-mobile {
        flex-direction: column;
    }

    .pamm-stats-mobile {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .pamm-stats-mobile div p {
        font-size: 12px !important;
    }

    .pamm-stats-mobile div p {
        font-size: 12px !important;
    }

    .pamm-stats-mobile div p:first-child{
        font-weight: 400;
    }

    #col-line-graph {
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }

    #displayGraph{
        padding: 0 ;
    }
}
