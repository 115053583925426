.newsRow {
    background-color: white;
}

.newsRow:hover {
    background-color: #c4c4c54d;
}

#newsDiv {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow: scroll;
}

#newsDiv::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.linkInNews {
    color: black !important;
}

.linkInNews.dark-only {
    color: #f9b600 !important;
}
