@media (max-width: 556px) {
    #card-align-statisticReferral {
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    #card-365-event {
        margin-top: 35px;
    }
}

@media (min-width: 1601px) {
    #card-365-event {
        margin-top: 35px;
    }
}

.introjs-helperNumberLayer,
.introjs-tooltipReferenceLayer,
.introjs-tooltipReferenceLayer * {
    font-family: inherit !important;
}

#div-action-bar {
    display: flex;
    align-items: center;
}

#div-button-action-bar {
    display: flex;
}

@media (max-width: 556px) {
    #div-action-bar {
        display: block;
    }

    #div-button-action-bar {
        display: flex;
        margin-top: 10px;
    }

    #div-button-action-bar div a button {
        margin-left: 0px !important;
    }
}

#settlementTooltip.tooltip-inner {
    max-width: 500px;
    border: 1px solid black;
}

@media (max-width: 556px) {
    #settlementTooltip.tooltip-inner {
        max-width: 300px;
        border: 1px solid black;
    }
}

.tooltip-inner {
    border: 1px solid black;
}

.tooltip-inner {
    border: 1px solid black;
}

#bonusTooltipText.tooltip-inner {
    text-align: left;
}

.tooltip.show {
    opacity: 1 !important;
}

.feedbackModal {
    width: 90%;
}

.rec.rec-arrow {
    background-color: #f9b700;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: #103c5a;
}

button.rec.rec-arrow {
    background-color: white;
    box-shadow: 0 0 1px 3px #103c5a;
}
button.rec-arrow:active,
button.rec-arrow:focus {
    box-shadow: 0 0 1px 3px #103c5a;
}

button.rec-arrow:active,
button.rec-arrow:focus {
    box-shadow: 0 0 1px 3px #103c5a;
}

button.rec-dot {
    background-color: white;
    box-shadow: 0 0 1px 3px #103c5a;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
    box-shadow: 0 0 1px 3px #103c5a;
}

.rec.rec-arrow:focus {
    border-radius: 50%;
    background-color: #103c5a;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec-pagination .rec-dot_active {
    background-color: #f9b700;
    box-shadow: 0 0 1px 3px #103c5a;
}

@media (max-width: 768px) {
    #carousel_description {
        font-size: 12px;
    }
}
