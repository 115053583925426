$iconic-font-path: '../../fonts/open-iconic/' !default;

@font-face {
  font-family: 'Icons';
  src: url('#{$iconic-font-path}open-iconic.eot');
  src: url('#{$iconic-font-path}open-iconic.eot?#iconic-sm') format('embedded-opentype'), url('#{$iconic-font-path}open-iconic.woff') format('woff'), url('#{$iconic-font-path}open-iconic.ttf') format('truetype'), url('#{$iconic-font-path}open-iconic.otf') format('opentype'), url('#{$iconic-font-path}open-iconic.svg#iconic-sm') format('svg');
  font-weight: normal;
  font-style: normal;
}

.oi[data-glyph].oi-text-replace {
  font-size: 0;
  line-height: 0;
}

.oi[data-glyph].oi-text-replace:before {
  width: 1em;
  text-align: center;
}

.oi[data-glyph] {
  &:before {
    position: relative;
    top: 1px;
    font-family: 'Icons';
    display: inline-block;
    speak: none;
    line-height: 1;
    vertical-align: baseline;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:empty:before {
    width: 1em;
    text-align: center;
    box-sizing: content-box;
  }

  &.oi-align-left:before {
    text-align: left;
  }
  &.oi-align-right:before {
    text-align: right;
  }
  &.oi-align-center:before {
    text-align: center;
  }

  &.oi-flip-horizontal:before {
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  &.oi-flip-vertical:before {
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(-1, 1);
    transform: scale(1, -1);
  }

  &.oi-flip-horizontal-vertical:before {
    -webkit-transform: scale(-1, -1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, -1);
  }
}


.oi[data-glyph=account-login]:before {
  content: '\e000';
}

.oi[data-glyph=account-logout]:before {
  content: '\e001';
}

.oi[data-glyph=action-redo]:before {
  content: '\e002';
}

.oi[data-glyph=action-undo]:before {
  content: '\e003';
}

.oi[data-glyph=align-center]:before {
  content: '\e004';
}

.oi[data-glyph=align-left]:before {
  content: '\e005';
}

.oi[data-glyph=align-right]:before {
  content: '\e006';
}

.oi[data-glyph=aperture]:before {
  content: '\e007';
}

.oi[data-glyph=arrow-bottom]:before {
  content: '\e008';
}

.oi[data-glyph=arrow-circle-bottom]:before {
  content: '\e009';
}

.oi[data-glyph=arrow-circle-left]:before {
  content: '\e00a';
}

.oi[data-glyph=arrow-circle-right]:before {
  content: '\e00b';
}

.oi[data-glyph=arrow-circle-top]:before {
  content: '\e00c';
}

.oi[data-glyph=arrow-left]:before {
  content: '\e00d';
}

.oi[data-glyph=arrow-right]:before {
  content: '\e00e';
}

.oi[data-glyph=arrow-thick-bottom]:before {
  content: '\e00f';
}

.oi[data-glyph=arrow-thick-left]:before {
  content: '\e010';
}

.oi[data-glyph=arrow-thick-right]:before {
  content: '\e011';
}

.oi[data-glyph=arrow-thick-top]:before {
  content: '\e012';
}

.oi[data-glyph=arrow-top]:before {
  content: '\e013';
}

.oi[data-glyph=audio-spectrum]:before {
  content: '\e014';
}

.oi[data-glyph=audio]:before {
  content: '\e015';
}

.oi[data-glyph=badge]:before {
  content: '\e016';
}

.oi[data-glyph=ban]:before {
  content: '\e017';
}

.oi[data-glyph=bar-chart]:before {
  content: '\e018';
}

.oi[data-glyph=basket]:before {
  content: '\e019';
}

.oi[data-glyph=battery-empty]:before {
  content: '\e01a';
}

.oi[data-glyph=battery-full]:before {
  content: '\e01b';
}

.oi[data-glyph=beaker]:before {
  content: '\e01c';
}

.oi[data-glyph=bell]:before {
  content: '\e01d';
}

.oi[data-glyph=bluetooth]:before {
  content: '\e01e';
}

.oi[data-glyph=bold]:before {
  content: '\e01f';
}

.oi[data-glyph=bolt]:before {
  content: '\e020';
}

.oi[data-glyph=book]:before {
  content: '\e021';
}

.oi[data-glyph=bookmark]:before {
  content: '\e022';
}

.oi[data-glyph=box]:before {
  content: '\e023';
}

.oi[data-glyph=briefcase]:before {
  content: '\e024';
}

.oi[data-glyph=british-pound]:before {
  content: '\e025';
}

.oi[data-glyph=browser]:before {
  content: '\e026';
}

.oi[data-glyph=brush]:before {
  content: '\e027';
}

.oi[data-glyph=bug]:before {
  content: '\e028';
}

.oi[data-glyph=bullhorn]:before {
  content: '\e029';
}

.oi[data-glyph=calculator]:before {
  content: '\e02a';
}

.oi[data-glyph=calendar]:before {
  content: '\e02b';
}

.oi[data-glyph=camera-slr]:before {
  content: '\e02c';
}

.oi[data-glyph=caret-bottom]:before {
  content: '\e02d';
}

.oi[data-glyph=caret-left]:before {
  content: '\e02e';
}

.oi[data-glyph=caret-right]:before {
  content: '\e02f';
}

.oi[data-glyph=caret-top]:before {
  content: '\e030';
}

.oi[data-glyph=cart]:before {
  content: '\e031';
}

.oi[data-glyph=chat]:before {
  content: '\e032';
}

.oi[data-glyph=check]:before {
  content: '\e033';
}

.oi[data-glyph=chevron-bottom]:before {
  content: '\e034';
}

.oi[data-glyph=chevron-left]:before {
  content: '\e035';
}

.oi[data-glyph=chevron-right]:before {
  content: '\e036';
}

.oi[data-glyph=chevron-top]:before {
  content: '\e037';
}

.oi[data-glyph=circle-check]:before {
  content: '\e038';
}

.oi[data-glyph=circle-x]:before {
  content: '\e039';
}

.oi[data-glyph=clipboard]:before {
  content: '\e03a';
}

.oi[data-glyph=clock]:before {
  content: '\e03b';
}

.oi[data-glyph=cloud-download]:before {
  content: '\e03c';
}

.oi[data-glyph=cloud-upload]:before {
  content: '\e03d';
}

.oi[data-glyph=cloud]:before {
  content: '\e03e';
}

.oi[data-glyph=cloudy]:before {
  content: '\e03f';
}

.oi[data-glyph=code]:before {
  content: '\e040';
}

.oi[data-glyph=cog]:before {
  content: '\e041';
}

.oi[data-glyph=collapse-down]:before {
  content: '\e042';
}

.oi[data-glyph=collapse-left]:before {
  content: '\e043';
}

.oi[data-glyph=collapse-right]:before {
  content: '\e044';
}

.oi[data-glyph=collapse-up]:before {
  content: '\e045';
}

.oi[data-glyph=command]:before {
  content: '\e046';
}

.oi[data-glyph=comment-square]:before {
  content: '\e047';
}

.oi[data-glyph=compass]:before {
  content: '\e048';
}

.oi[data-glyph=contrast]:before {
  content: '\e049';
}

.oi[data-glyph=copywriting]:before {
  content: '\e04a';
}

.oi[data-glyph=credit-card]:before {
  content: '\e04b';
}

.oi[data-glyph=crop]:before {
  content: '\e04c';
}

.oi[data-glyph=dashboard]:before {
  content: '\e04d';
}

.oi[data-glyph=data-transfer-download]:before {
  content: '\e04e';
}

.oi[data-glyph=data-transfer-upload]:before {
  content: '\e04f';
}

.oi[data-glyph=delete]:before {
  content: '\e050';
}

.oi[data-glyph=dial]:before {
  content: '\e051';
}

.oi[data-glyph=document]:before {
  content: '\e052';
}

.oi[data-glyph=dollar]:before {
  content: '\e053';
}

.oi[data-glyph=double-quote-sans-left]:before {
  content: '\e054';
}

.oi[data-glyph=double-quote-sans-right]:before {
  content: '\e055';
}

.oi[data-glyph=double-quote-serif-left]:before {
  content: '\e056';
}

.oi[data-glyph=double-quote-serif-right]:before {
  content: '\e057';
}

.oi[data-glyph=droplet]:before {
  content: '\e058';
}

.oi[data-glyph=eject]:before {
  content: '\e059';
}

.oi[data-glyph=elevator]:before {
  content: '\e05a';
}

.oi[data-glyph=ellipses]:before {
  content: '\e05b';
}

.oi[data-glyph=envelope-closed]:before {
  content: '\e05c';
}

.oi[data-glyph=envelope-open]:before {
  content: '\e05d';
}

.oi[data-glyph=euro]:before {
  content: '\e05e';
}

.oi[data-glyph=excerpt]:before {
  content: '\e05f';
}

.oi[data-glyph=expand-down]:before {
  content: '\e060';
}

.oi[data-glyph=expand-left]:before {
  content: '\e061';
}

.oi[data-glyph=expand-right]:before {
  content: '\e062';
}

.oi[data-glyph=expand-up]:before {
  content: '\e063';
}

.oi[data-glyph=external-link]:before {
  content: '\e064';
}

.oi[data-glyph=eye]:before {
  content: '\e065';
}

.oi[data-glyph=eyedropper]:before {
  content: '\e066';
}

.oi[data-glyph=file]:before {
  content: '\e067';
}

.oi[data-glyph=fire]:before {
  content: '\e068';
}

.oi[data-glyph=flag]:before {
  content: '\e069';
}

.oi[data-glyph=flash]:before {
  content: '\e06a';
}

.oi[data-glyph=folder]:before {
  content: '\e06b';
}

.oi[data-glyph=fork]:before {
  content: '\e06c';
}

.oi[data-glyph=fullscreen-enter]:before {
  content: '\e06d';
}

.oi[data-glyph=fullscreen-exit]:before {
  content: '\e06e';
}

.oi[data-glyph=globe]:before {
  content: '\e06f';
}

.oi[data-glyph=graph]:before {
  content: '\e070';
}

.oi[data-glyph=grid-four-up]:before {
  content: '\e071';
}

.oi[data-glyph=grid-three-up]:before {
  content: '\e072';
}

.oi[data-glyph=grid-two-up]:before {
  content: '\e073';
}

.oi[data-glyph=hard-drive]:before {
  content: '\e074';
}

.oi[data-glyph=header]:before {
  content: '\e075';
}

.oi[data-glyph=headphones]:before {
  content: '\e076';
}

.oi[data-glyph=heart]:before {
  content: '\e077';
}

.oi[data-glyph=home]:before {
  content: '\e078';
}

.oi[data-glyph=image]:before {
  content: '\e079';
}

.oi[data-glyph=inbox]:before {
  content: '\e07a';
}

.oi[data-glyph=infinity]:before {
  content: '\e07b';
}

.oi[data-glyph=info]:before {
  content: '\e07c';
}

.oi[data-glyph=italic]:before {
  content: '\e07d';
}

.oi[data-glyph=justify-center]:before {
  content: '\e07e';
}

.oi[data-glyph=justify-left]:before {
  content: '\e07f';
}

.oi[data-glyph=justify-right]:before {
  content: '\e080';
}

.oi[data-glyph=key]:before {
  content: '\e081';
}

.oi[data-glyph=laptop]:before {
  content: '\e082';
}

.oi[data-glyph=layers]:before {
  content: '\e083';
}

.oi[data-glyph=lightbulb]:before {
  content: '\e084';
}

.oi[data-glyph=link-broken]:before {
  content: '\e085';
}

.oi[data-glyph=link-intact]:before {
  content: '\e086';
}

.oi[data-glyph=list-rich]:before {
  content: '\e087';
}

.oi[data-glyph=list]:before {
  content: '\e088';
}

.oi[data-glyph=location]:before {
  content: '\e089';
}

.oi[data-glyph=lock-locked]:before {
  content: '\e08a';
}

.oi[data-glyph=lock-unlocked]:before {
  content: '\e08b';
}

.oi[data-glyph=loop-circular]:before {
  content: '\e08c';
}

.oi[data-glyph=loop-square]:before {
  content: '\e08d';
}

.oi[data-glyph=loop]:before {
  content: '\e08e';
}

.oi[data-glyph=magnifying-glass]:before {
  content: '\e08f';
}

.oi[data-glyph=map-marker]:before {
  content: '\e090';
}

.oi[data-glyph=map]:before {
  content: '\e091';
}

.oi[data-glyph=media-pause]:before {
  content: '\e092';
}

.oi[data-glyph=media-play]:before {
  content: '\e093';
}

.oi[data-glyph=media-record]:before {
  content: '\e094';
}

.oi[data-glyph=media-skip-backward]:before {
  content: '\e095';
}

.oi[data-glyph=media-skip-forward]:before {
  content: '\e096';
}

.oi[data-glyph=media-step-backward]:before {
  content: '\e097';
}

.oi[data-glyph=media-step-forward]:before {
  content: '\e098';
}

.oi[data-glyph=media-stop]:before {
  content: '\e099';
}

.oi[data-glyph=medical-cross]:before {
  content: '\e09a';
}

.oi[data-glyph=menu]:before {
  content: '\e09b';
}

.oi[data-glyph=microphone]:before {
  content: '\e09c';
}

.oi[data-glyph=minus]:before {
  content: '\e09d';
}

.oi[data-glyph=monitor]:before {
  content: '\e09e';
}

.oi[data-glyph=moon]:before {
  content: '\e09f';
}

.oi[data-glyph=move]:before {
  content: '\e0a0';
}

.oi[data-glyph=musical-note]:before {
  content: '\e0a1';
}

.oi[data-glyph=paperclip]:before {
  content: '\e0a2';
}

.oi[data-glyph=pencil]:before {
  content: '\e0a3';
}

.oi[data-glyph=people]:before {
  content: '\e0a4';
}

.oi[data-glyph=person]:before {
  content: '\e0a5';
}

.oi[data-glyph=phone]:before {
  content: '\e0a6';
}

.oi[data-glyph=pie-chart]:before {
  content: '\e0a7';
}

.oi[data-glyph=pin]:before {
  content: '\e0a8';
}

.oi[data-glyph=play-circle]:before {
  content: '\e0a9';
}

.oi[data-glyph=plus]:before {
  content: '\e0aa';
}

.oi[data-glyph=power-standby]:before {
  content: '\e0ab';
}

.oi[data-glyph=print]:before {
  content: '\e0ac';
}

.oi[data-glyph=project]:before {
  content: '\e0ad';
}

.oi[data-glyph=pulse]:before {
  content: '\e0ae';
}

.oi[data-glyph=puzzle-piece]:before {
  content: '\e0af';
}

.oi[data-glyph=question-mark]:before {
  content: '\e0b0';
}

.oi[data-glyph=rain]:before {
  content: '\e0b1';
}

.oi[data-glyph=random]:before {
  content: '\e0b2';
}

.oi[data-glyph=reload]:before {
  content: '\e0b3';
}

.oi[data-glyph=resize-both]:before {
  content: '\e0b4';
}

.oi[data-glyph=resize-height]:before {
  content: '\e0b5';
}

.oi[data-glyph=resize-width]:before {
  content: '\e0b6';
}

.oi[data-glyph=rss-alt]:before {
  content: '\e0b7';
}

.oi[data-glyph=rss]:before {
  content: '\e0b8';
}

.oi[data-glyph=script]:before {
  content: '\e0b9';
}

.oi[data-glyph=share-boxed]:before {
  content: '\e0ba';
}

.oi[data-glyph=share]:before {
  content: '\e0bb';
}

.oi[data-glyph=shield]:before {
  content: '\e0bc';
}

.oi[data-glyph=signal]:before {
  content: '\e0bd';
}

.oi[data-glyph=signpost]:before {
  content: '\e0be';
}

.oi[data-glyph=sort-ascending]:before {
  content: '\e0bf';
}

.oi[data-glyph=sort-descending]:before {
  content: '\e0c0';
}

.oi[data-glyph=spreadsheet]:before {
  content: '\e0c1';
}

.oi[data-glyph=star]:before {
  content: '\e0c2';
}

.oi[data-glyph=sun]:before {
  content: '\e0c3';
}

.oi[data-glyph=tablet]:before {
  content: '\e0c4';
}

.oi[data-glyph=tag]:before {
  content: '\e0c5';
}

.oi[data-glyph=tags]:before {
  content: '\e0c6';
}

.oi[data-glyph=target]:before {
  content: '\e0c7';
}

.oi[data-glyph=task]:before {
  content: '\e0c8';
}

.oi[data-glyph=terminal]:before {
  content: '\e0c9';
}

.oi[data-glyph=text]:before {
  content: '\e0ca';
}

.oi[data-glyph=thumb-down]:before {
  content: '\e0cb';
}

.oi[data-glyph=thumb-up]:before {
  content: '\e0cc';
}

.oi[data-glyph=timer]:before {
  content: '\e0cd';
}

.oi[data-glyph=transfer]:before {
  content: '\e0ce';
}

.oi[data-glyph=trash]:before {
  content: '\e0cf';
}

.oi[data-glyph=underline]:before {
  content: '\e0d0';
}

.oi[data-glyph=vertical-align-bottom]:before {
  content: '\e0d1';
}

.oi[data-glyph=vertical-align-center]:before {
  content: '\e0d2';
}

.oi[data-glyph=vertical-align-top]:before {
  content: '\e0d3';
}

.oi[data-glyph=video]:before {
  content: '\e0d4';
}

.oi[data-glyph=volume-high]:before {
  content: '\e0d5';
}

.oi[data-glyph=volume-low]:before {
  content: '\e0d6';
}

.oi[data-glyph=volume-off]:before {
  content: '\e0d7';
}

.oi[data-glyph=warning]:before {
  content: '\e0d8';
}

.oi[data-glyph=wifi]:before {
  content: '\e0d9';
}

.oi[data-glyph=wrench]:before {
  content: '\e0da';
}

.oi[data-glyph=x]:before {
  content: '\e0db';
}

.oi[data-glyph=yen]:before {
  content: '\e0dc';
}

.oi[data-glyph=zoom-in]:before {
  content: '\e0dd';
}

.oi[data-glyph=zoom-out]:before {
  content: '\e0de';
}

