.progress {
    width: 70%;
    height: 11px;
    margin: 0px auto;
}

#selectPAMMinput {
    width: 50%;
}

@media (max-width: 768px) {
    #selectPAMMinput {
        width: 100%;
        margin-bottom: 15px;
    }
}
